import moment from 'moment';

export const timeFormatLongAmPm = 'h:mm:ss A';
const FP_WEAR_TIME_LOG = 'FP_WEAR_TIME_LOG';

export const getHourForUtc = () => {
  const offsetInSeconds = moment().utcOffset();
  const inHour = `${moment('1900-01-01 00:00:00')
    .add(Math.abs(offsetInSeconds), 'seconds')
    .format('mm:ss')}`; // for getting the difference from the duration
  if (offsetInSeconds < 0) return `UTC-${inHour}`;
  return `UTC+${inHour}`;
};

export const formatTime = (hours, minutes, seconds) => {
  const formattedHour = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattesSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return {
    hours: formattedHour,
    minutes: formattedMinutes,
    seconds: formattesSeconds
  };
};

export const formatNumberToTime = (number) => {
  const secNum = Number(number);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor((secNum - hours * 3600) / 60);
  const seconds = secNum - hours * 3600 - minutes * 60;
  return formatTime(hours, minutes, seconds);
};

export const TIMER_STATUSES = {
  PLAY: 'PLAY',
  PAUSE: 'PAUSE'
};

export const WEAR_TIME_EDIT_OPTIONS = {
  ADD: 'ADD',
  MINUS: 'MINUS',
  CUSTOM: 'CUSTOM'
};

export const getCurrentUtcTimestamp = (date = moment()) => (
  moment(date).format('YYYY-MM-DD')
);

export const getWearTimeFromDangledPause = (dangledDate) => {
  const dangledDay = moment(dangledDate);
  const now = moment();
  const dangledDateArray = [];
  if (dangledDay.isSame(now, 'day')) {
    const intervalTime = now.diff(dangledDay, 'seconds');
    dangledDateArray.push({
      start: `${dangledDay.utc().toISOString().split('.')[0]}Z`,
      duration: intervalTime,
      cause: 'dangled'
    });
  } else {
    while (dangledDay.isSameOrBefore(now)) {
      const currentDay = moment(dangledDay);
      const currentDayEOD = moment(dangledDay).endOf('day');
      const intervalTime = currentDayEOD.diff(currentDay, 'seconds');
      dangledDateArray.push({
        start: `${dangledDay.utc().toISOString().split('.')[0]}Z`,
        duration: intervalTime,
        cause: 'dangled'
      });
      dangledDay.add(1, 'days').startOf('day');
    }
  }
  return dangledDateArray;
};

export const removeDuplicateObject = (dataArray, dates) => {
  const cur = {};
  const Hr24InSec = 86400; // 24 hours in seconds

  // Step 1: Organize objects by date, merging durations if the same date already exists
  dataArray.forEach((obj) => {
    const key = moment(obj.start).startOf('day').toISOString();
    if (cur[key]) {
      cur[key].duration += obj.duration; // Merge duration if date exists
    } else {
      cur[key] = { ...obj, start: key }; // Initialize new entry
    }
  });

  // date range of search
  const startDate = moment(dates.firstDate).startOf('day');
  const endDate = moment(dates.lastDate);

  // Generate the complete range and fill missing dates
  const currentDate = startDate.clone();
  while (currentDate.isSameOrBefore(endDate)) {
    const dateKey = currentDate.utc().toISOString();
    if (!cur[dateKey]) {
      // Add custom object for missing dates
      cur[dateKey] = {
        start: dateKey,
        duration: Hr24InSec,
        cause: 'noDataFound',
        user_date_str: dateKey
      };
    }
    currentDate.add(1, 'days');
  }

  // Return the final array of objects
  return Object.values(cur);
};

export const isWearTimeFeatureEnabled = (
  featureStatusResponse
) => !!featureStatusResponse?.plugins?.includes?.(FP_WEAR_TIME_LOG);
