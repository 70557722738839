const storageKeys = {
  cartId: 'zenyum_ecommerce_cart_id',
  clinicCloudToken: 'zenyum_clinic_cloud_token',
  csIdentifier: 'zenyum_cs_identifier',
  isRedirectedToRefinement: 'is_redirected_to_refinement',
  comingFrom: 'treatment_plan_coming_from',
  showForgotSwitchCard: 'showForgotSwitchCard',
  isValidUser: 'isValidUser',
  redirectToTreatmentPlan: 'redirectToTreatmentPlan'
};

export function setCookie(key, value) {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  document.cookie = `${key}=${value}; expires= ${currentDate}`;
}
export function setDayCookie(key, value) {
  const currentDate = new Date();
  currentDate.setHours(23, 59, 59);
  document.cookie = `${key}=${value}; expires= ${currentDate.toUTCString()}`;
}

export function getCookie(key) {
  const searchKey = `${key}=`;
  const cookies = document.cookie ? document.cookie.split('; ') : [];
  const result = cookies.find((cookie) => cookie.includes(searchKey));
  if (result) {
    return result.split('=')[1];
  }
  return null;
}

export const getLocalStorageItem = (key) => localStorage.getItem(storageKeys[key]);

export const setLocalStorageItem = (key, value) => localStorage.setItem(storageKeys[key], value);

export const removeLocalStorageItem = (key) => localStorage.removeItem(storageKeys[key]);

export const clearLocalStorageItems = () => {
  Object.keys(storageKeys).forEach((key) => {
    localStorage.removeItem(storageKeys[key]);
  });
};
