/* eslint-disable import/prefer-default-export */
export const pageviewEvent = (page, country, time) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.page(page, {
      context: {
        timespent: time,
        location: {
          country
        }
      }
    });
  }
};
